import { Card, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";


const Info = () => {

    const [data, setData]= useState(null);

    useEffect (() => {
        fetch ("http://localhost:3001/api")
          .then((res) => res.json())
          .then((data) => setData(data.message))
          .catch(err => console.error(err))
        ;
      }, []); 
    


    return (
        <Card variant="elevation" elevation={12}
        sx={{
          textAlign: "center",
          minHeight: 100,
          position: "relative",
          weight: "80%",
          backgroundColor: "rgb(180, 192, 180)",
          color: "rgb(75,76,79)",
          marginTop: "5px",
          padding: "2px",
          fontFamily: "serif",
          fontSize: "1.5rem",
          margin: "1rem"
        }}>
        
          
          <Typography variant="h5" underline="true"> 
            Vi-Auto
          </Typography>
          <Typography variant="h5" lineHeight={"1"}>
            <strong>
              KÄYTETYT RENKAAT ERINOMAISESSA KUNNOSSA
            </strong>
          </Typography>

          <Typography variant="h6" fontFamily={"serif"} fontSize={"1.2rem"} lineHeight={"1"} >
            WhatsApp ja tekstiviesti:
            +&nbsp;358&nbsp; 41 &nbsp; 728 &nbsp; 4897 <br/>
            email:&nbsp;tilaus@kumi360.fi
          </Typography>
          <Typography variant="h6" fontFamily={"serif"} fontSize={"1.2rem"} lineHeight={"1"} >
            Tukkukauppa:
            + &nbsp; 358 &nbsp; 40 &nbsp; 688 &nbsp; 6665 &nbsp; Enri<br/>
          </Typography>

          <p/>
            {!data ? <p style={{ color: "red" }}> 
            {/* Loading...  */}
          </p> : data } 
    
        </Card>
    );
};

export default Info;