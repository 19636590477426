import { Box, Pagination, useMediaQuery } from "@mui/material";
import { useTheme } from '@emotion/react';
import React, { useState, useEffect } from "react";

const CustomPagination = ({ filteredList, itemsPerPage, onPageItemsChange }) => {

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [currentPage, setCurrentPage] = useState(1);

  const totalPages = Math.ceil(filteredList.length / itemsPerPage);

  useEffect(() => {
    if (currentPage > totalPages && totalPages > 0) {
        setCurrentPage(1); 
    }
}, [totalPages]);

const handlePageChange = (event, page) => {
  setCurrentPage(page);
  const startIndex = (page - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;
  onPageItemsChange(filteredList.slice(startIndex, endIndex));
};

useEffect(() => {
  handlePageChange(null, 1);
}, [filteredList]);

if (totalPages <= 1) return null;
  return (
    <>
      <Box 
        display="flex" 
        justifyContent="center" 
        mt={2} 
        position="fixed" 
        bottom={0} 
        left={0} 
        right={0} 
        bgcolor="background.paper" 
        padding={2}
        zIndex={1000}
        >
        <Pagination 
          count={totalPages} 
          page={currentPage} 
          onChange={handlePageChange} 
          color="primary" 
          variant="outlined"
          size={isSmallScreen ? "small" : "medium"} 
          siblingCount={isSmallScreen ? 0 : 1} 
          boundaryCount={1}
          sx={{
            "& .MuiPaginationItem-root": {
            minWidth: "2rem", 
            minHeight: "2rem", 
            borderRadius: "0.2rem", 
            alignItems: "center",
            justifyContent: "center",
          },
          }}
        />
      </Box>
    </>
  );
}

export default CustomPagination;
