import { Card, CardContent, CardMedia, Typography } from "@mui/material";
import { useState } from "react";
import DrawerBottom from "../common/DrawerBottom";
import "./TiresList.css";

const OneRenka = ({ tire }) => {

 const[open, setOpen] = useState(false);


  return (
    <>
    <Card variant="elevation" elevation={12} className="tire-card"
      sx={{
        backgroundColor: "#E7EAE7",
        color: "rgb(75,76,79)",
        height: "15rem",
        position: "relative"
        }}>

    <CardContent>

      <Typography gutterBottom component="div"
        sx={{
          height: "50px",
          typography: { sm: 'body1', xs: 'body2' }
          }} >
        {tire.model}
      </Typography>

      <Typography sx={{ fontWeight: 'bold' }}>
        {tire.size}
      </Typography>

        <CardMedia
          component="img"
          alt="renkaat"
          height="100"
          image={tire.images.image1}
          onClick={() => setOpen(true)}
          loading="lazy"
        />

      <Typography variant="body1"
        sx={{
          fontSize: "0.8rem",
          float: "left"
        }}>
        {tire.id}
      </Typography>

      <Typography variant="body1"
        sx={{
          fontSize: "0.8rem",
          float: "right"
        }}>
        {tire.quantity}kpl
      </Typography>


      <Typography variant="h6"
        sx={{
          color: "rgb(196, 39, 39)",
          position: "absolute",
          bottom: "2px",
          right: "8px"
        }
        }>
          {tire.price}
      </Typography>

    </CardContent>

    </Card>

    <DrawerBottom
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      attachment={tire} />
    </>
    );
};

export default OneRenka;