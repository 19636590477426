import { Box, SwipeableDrawer, Typography } from "@mui/material";
import React, { useState, useEffect } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import ImagesAll from "../images/ImagesAll";
import "./Common.css";

const DrawerBottom = ({ open, onClose, onOpen, attachment }) => {

  const [additionalImages, setAdditionalImages] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (open && attachment) {
        setLoading(true);
        fetchAdditionalImages(attachment.id)
            .then((images) => {
                setAdditionalImages(images);
                setLoading(false);
            })
            .catch(() => {
                setAdditionalImages([]);
                setLoading(false);
            });
    } else {
        setAdditionalImages([]);
    }
}, [open, attachment]);

const fetchAdditionalImages = async (attachmentId) => {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(attachment.images || []);
        }, 1000);
    });
};

  return (
    <SwipeableDrawer
      anchor="bottom"
      open={open}
      onClose={onClose}
      onOpen={onOpen}
      PaperProps={{
        className: "drawer",
        sx: {
          height: 750
        }
      }}
    >
      <CloseIcon 
        onClick={onClose}
        sx={{
          position: "absolute",
          top: "0.5rem",
          right: "0.5rem"
      }}/>

      <Box className="header-box">
        <Typography sx={{ fontWeight: 'bold', fontSize: "20px", marginTop: "5px" }}>
          {attachment.model}
        </Typography>

        <Typography>
          Koko: {attachment.size}  {attachment.season}
        </Typography>
      </Box>


        <Box className="image-box"
          sx={{ 
            flexGrow: 1, 
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            position: "relative",
            height: "50%",
         }}>
        {loading ? (
          <Typography
            sx={{
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)", 
              fontSize: "1rem",
              color: "grey.600",
            }}>
            loading...
          </Typography>
        ) : (
          <ImagesAll images={additionalImages}/> 
          )}
          <Typography variant="body1"
            sx={{
              marginTop: "2px", // Отступ сверху для читаемости
              marginRight: "80%",
              fontSize: "0.8rem",
              backgroundColor: "rgba(255, 255, 255, 0.8)",  
              borderRadius: "4px" 
            }}>
            {attachment.id}  
          </Typography>
        </Box>


      <Box className="footer-box" sx={{ paddingTop: "0.2rem"}}>
        <Typography >
          Vuosi: {attachment.year}
        </Typography>

        <Typography>
          Pintaa: {attachment.pinta}
        </Typography>

        <Typography className="kpl">
          {attachment.quantity}kpl
        </Typography>

        <Typography variant="h4" className="price">
              {attachment.price} 
        </Typography>
      </Box>

    </SwipeableDrawer>
  );
};

export default DrawerBottom;
